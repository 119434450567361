import React, { useEffect, useState } from "react";
import anime from "animejs";
import ElementorButton from "../../../components/elementorButton/elementorButton";
import "./style.css";
import { Link } from "react-router-dom";

function HeroSection() {
  useEffect(() => {
    function fitElementToParent(el, padding) {
      var timeout = null;
      function resize() {
        if (timeout) clearTimeout(timeout);
        anime.set(el, { scale: 1 });
        var pad = padding || 0;
        var parentEl = el.parentNode;
        var elOffsetWidth = el.offsetWidth - pad;
        var parentOffsetWidth = parentEl.offsetWidth;
        var ratio = parentOffsetWidth / elOffsetWidth;
        timeout = setTimeout(anime.set(el, { scale: ratio }), 10);
      }
      resize();
      window.addEventListener("resize", resize);
    }

    var sphereAnimation = (function () {
      var sphereEl = document.querySelector(".sphere-animation");
      var spherePathEls = sphereEl.querySelectorAll(".sphere path");
      var pathLength = spherePathEls.length;
      var hasStarted = false;
      var aimations = [];

      fitElementToParent(sphereEl);

      var breathAnimation = anime({
        begin: function () {
          for (var i = 0; i < pathLength; i++) {
            aimations.push(
              anime({
                targets: spherePathEls[i],
                stroke: {
                  value: ["rgb(248,169,37,1)", "rgba(80,80,80,.35)"],
                  duration: 500,
                },
                translateX: [2, -4],
                translateY: [2, -4],
                easing: "easeOutQuad",
                autoplay: false,
              })
            );
          }
        },
        update: function (ins) {
          aimations.forEach(function (animation, i) {
            var percent =
              (1 - Math.sin(i * 0.35 + 0.0022 * ins.currentTime)) / 2;
            animation.seek(animation.duration * percent);
          });
        },
        duration: Infinity,
        autoplay: false,
      });

      var introAnimation = anime
        .timeline({
          autoplay: false,
        })
        .add(
          {
            targets: spherePathEls,
            // strokeDashoffset: {
            //   value: [anime.setDashoffset, 0],
            //   duration: 3900,
            //   easing: 'easeInOutCirc',
            //   delay: anime.stagger(190, {direction: 'reverse'})
            // },
            duration: 2000,
            delay: anime.stagger(60, { direction: "reverse" }),
            easing: "linear",
          },
          0
        );

      var shadowAnimation = anime(
        {
          targets: "#sphereGradient",
          x1: "25%",
          x2: "25%",
          y1: "0%",
          y2: "75%",
          duration: 30000,
          easing: "easeOutQuint",
          autoplay: false,
        },
        0
      );

      function init() {
        introAnimation.play();
        breathAnimation.play();
        shadowAnimation.play();
      }

      init();
    })();
  }, []);

  const [cursorPosition, setCursorPosition] = useState({
    transform:
      "translateX(49.03194578896418px) translateY(-6.599788806758184px)",
  });
  const onMouseMove = (e) => {
    // e * t / 100
    setCursorPosition({
      transform:
        "translateX(" +
        (49.03194578896418 * e.screenY) / 600 +
        "px) translateY(" +
        (-6.599788806758184 * e.screenY) / 600 +
        "px)",
    });
  };

  return (
    <>
      <section>
        <div
          className="HeroSection"
          onMouseMove={onMouseMove}
          style={{ overflow: "hidden", position: "relative" }}
        >
          <div className="container">
            <div className="row mt-5" style={{ height: "80vh" }}>
              <div
                className="col-md verticle_center_heroSection_left py-3 order-3 order-md-2"
                style={{ position: "relative" }}
              >
                <span className="gf_h1">Your Digital Wingman</span>
                <p className="gf_text pt-2" style={{ color: "#FFFFFF99" }}>
                  Crafting Buzz, Igniting Growth: <br />
                  Where Brands Dare to Be Iconic!
                </p>
                <br />
                <br />
                <ElementorButton
                  mode="dark"
                  
                  URL="/contact"
                  text="Tap to Talk"
                />
                {/* <br /><br /> */}

                <div
                  style={{
                    position: "absolute",
                    left: "-30px",
                    bottom: "0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "3px",
                      backgroundColor: "#fff",
                      height: 60,
                      marginBottom: 20,
                    }}
                  >
                    {/* dash */}
                  </div>

                  <Link
                    className="social-icon"
                    to="https://www.instagram.com/wodo.digital/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>

                  <Link
                    className="social-icon"
                    to="https://www.facebook.com/wodoagency"
                    target="_blank"
                  >
                    <i className="fab fa-facebook"></i>
                  </Link>

                  <Link
                    className="social-icon"
                    to="https://www.linkedin.com/company/wodo-digital"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </div>
              </div>
              <div
                className="col-md verticle_center_heroSection_right order-2 order-md-3"
                style={{ position: "relative" }}
                id="anime_test"
              >
                {/* <div className='FlipBox FlipBox_position_1'>
                            Test 1
                        </div>

                        <div className='FlipBox FlipBox_position_2'>
                            Test 2
                        </div>

                        <div className='FlipBox FlipBox_position_3'>
                            Test 3
                        </div> */}

                <div className="flip-box FlipBox_position_1 ">
                  <div className="flip-box-inner">
                    <div className="flip-box-front verticle_center">
                      <span className="flipbox-num">50+</span>
                      <span className="flipbox-text">Industry Best Tools</span>
                    </div>
                    <div className="flip-box-back verticle_center">
                      <span className="flipbox-num">200+</span>
                      <span className="flipbox-text">Development Projects </span>
                    </div>
                  </div>
                </div>

                <div className="flip-box FlipBox_position_2 ">
                  <div className="flip-box-inner">
                    <div className="flip-box-front verticle_center">
                      <span className="flipbox-num">500M</span>
                      <span className="flipbox-text">Reach Campaign</span>
                    </div>
                    <div className="flip-box-back verticle_center">
                      <span className="flipbox-num">31%</span>
                      <span className="flipbox-text">
                        Increase in Retention
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flip-box FlipBox_position_3 ">
                  <div className="flip-box-inner">
                    <div className="flip-box-front verticle_center">
                      <span className="flipbox-num">6x</span>
                      <span className="flipbox-text">Increased ROAS</span>
                    </div>
                    <div className="flip-box-back verticle_center">
                      <span className="flipbox-num">3x</span>
                      <span className="flipbox-text">Revenue Growth</span>
                    </div>
                  </div>
                </div>

                <div className="animation-wrapper ">
                  <div className="sphere-animation">
                    <svg
                      className="sphere"
                      viewBox="0 0 440 440"
                      stroke="rgba(80,80,80,.35)"
                    >
                      <defs>
                        <linearGradient
                          id="sphereGradient"
                          x1="5%"
                          x2="5%"
                          y1="0%"
                          y2="15%"
                        >
                          <stop stopColor="#373734" offset="0%" />
                          <stop stopColor="#242423" offset="50%" />
                          <stop stopColor="#0D0D0C" offset="100%" />
                        </linearGradient>
                      </defs>
                      <path d="M361.604 361.238c-24.407 24.408-51.119 37.27-59.662 28.727-8.542-8.543 4.319-35.255 28.726-59.663 24.408-24.407 51.12-37.269 59.663-28.726 8.542 8.543-4.319 35.255-28.727 59.662z" />
                      <path d="M360.72 360.354c-35.879 35.88-75.254 54.677-87.946 41.985-12.692-12.692 6.105-52.067 41.985-87.947 35.879-35.879 75.254-54.676 87.946-41.984 12.692 12.692-6.105 52.067-41.984 87.946z" />
                      <path d="M357.185 356.819c-44.91 44.91-94.376 68.258-110.485 52.149-16.11-16.11 7.238-65.575 52.149-110.485 44.91-44.91 94.376-68.259 110.485-52.15 16.11 16.11-7.239 65.576-52.149 110.486z" />
                      <path d="M350.998 350.632c-53.21 53.209-111.579 81.107-130.373 62.313-18.794-18.793 9.105-77.163 62.314-130.372 53.209-53.21 111.579-81.108 130.373-62.314 18.794 18.794-9.105 77.164-62.314 130.373z" />
                      <path d="M343.043 342.677c-59.8 59.799-125.292 91.26-146.283 70.268-20.99-20.99 10.47-86.483 70.269-146.282 59.799-59.8 125.292-91.26 146.283-70.269 20.99 20.99-10.47 86.484-70.27 146.283z" />
                      <path d="M334.646 334.28c-65.169 65.169-136.697 99.3-159.762 76.235-23.065-23.066 11.066-94.593 76.235-159.762s136.697-99.3 159.762-76.235c23.065 23.065-11.066 94.593-76.235 159.762z" />
                      <path d="M324.923 324.557c-69.806 69.806-146.38 106.411-171.031 81.76-24.652-24.652 11.953-101.226 81.759-171.032 69.806-69.806 146.38-106.411 171.031-81.76 24.652 24.653-11.953 101.226-81.759 171.032z" />
                      <path d="M312.99 312.625c-73.222 73.223-153.555 111.609-179.428 85.736-25.872-25.872 12.514-106.205 85.737-179.428s153.556-111.609 179.429-85.737c25.872 25.873-12.514 106.205-85.737 179.429z" />
                      <path d="M300.175 299.808c-75.909 75.909-159.11 115.778-185.837 89.052-26.726-26.727 13.143-109.929 89.051-185.837 75.908-75.908 159.11-115.778 185.837-89.051 26.726 26.726-13.143 109.928-89.051 185.836z" />
                      <path d="M284.707 284.34c-77.617 77.617-162.303 118.773-189.152 91.924-26.848-26.848 14.308-111.534 91.924-189.15C265.096 109.496 349.782 68.34 376.63 95.188c26.849 26.849-14.307 111.535-91.923 189.151z" />
                      <path d="M269.239 267.989c-78.105 78.104-163.187 119.656-190.035 92.807-26.849-26.848 14.703-111.93 92.807-190.035 78.105-78.104 163.187-119.656 190.035-92.807 26.849 26.848-14.703 111.93-92.807 190.035z" />
                      <path d="M252.887 252.52C175.27 330.138 90.584 371.294 63.736 344.446 36.887 317.596 78.043 232.91 155.66 155.293 233.276 77.677 317.962 36.521 344.81 63.37c26.85 26.848-14.307 111.534-91.923 189.15z" />
                      <path d="M236.977 236.61C161.069 312.52 77.867 352.389 51.14 325.663c-26.726-26.727 13.143-109.928 89.052-185.837 75.908-75.908 159.11-115.777 185.836-89.05 26.727 26.726-13.143 109.928-89.051 185.836z" />
                      <path d="M221.067 220.7C147.844 293.925 67.51 332.31 41.639 306.439c-25.873-25.873 12.513-106.206 85.736-179.429C200.6 53.786 280.931 15.4 306.804 41.272c25.872 25.873-12.514 106.206-85.737 179.429z" />
                      <path d="M205.157 204.79c-69.806 69.807-146.38 106.412-171.031 81.76-24.652-24.652 11.953-101.225 81.759-171.031 69.806-69.807 146.38-106.411 171.031-81.76 24.652 24.652-11.953 101.226-81.759 171.032z" />
                      <path d="M189.247 188.881c-65.169 65.169-136.696 99.3-159.762 76.235-23.065-23.065 11.066-94.593 76.235-159.762s136.697-99.3 159.762-76.235c23.065 23.065-11.066 94.593-76.235 159.762z" />
                      <path d="M173.337 172.971c-59.799 59.8-125.292 91.26-146.282 70.269-20.991-20.99 10.47-86.484 70.268-146.283 59.8-59.799 125.292-91.26 146.283-70.269 20.99 20.991-10.47 86.484-70.269 146.283z" />
                      <path d="M157.427 157.061c-53.209 53.21-111.578 81.108-130.372 62.314-18.794-18.794 9.104-77.164 62.313-130.373 53.21-53.209 111.58-81.108 130.373-62.314 18.794 18.794-9.105 77.164-62.314 130.373z" />
                      <path d="M141.517 141.151c-44.91 44.91-94.376 68.259-110.485 52.15-16.11-16.11 7.239-65.576 52.15-110.486 44.91-44.91 94.375-68.258 110.485-52.15 16.109 16.11-7.24 65.576-52.15 110.486z" />
                      <path d="M125.608 125.241c-35.88 35.88-75.255 54.677-87.947 41.985-12.692-12.692 6.105-52.067 41.985-87.947C115.525 43.4 154.9 24.603 167.592 37.295c12.692 12.692-6.105 52.067-41.984 87.946z" />
                      <path d="M109.698 109.332c-24.408 24.407-51.12 37.268-59.663 28.726-8.542-8.543 4.319-35.255 28.727-59.662 24.407-24.408 51.12-37.27 59.662-28.727 8.543 8.543-4.319 35.255-28.726 59.663z" />
                    </svg>

                    {/* <img loading="lazy" decoding="async" width="914" height="42" src="https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow.png" className="attachment-large size-large wp-image-5554 entered lazyloaded" alt="Digital Marketing Agency For Startups" data-lazy-srcset="https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow.png 914w, https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow-768x35.png 768w" data-lazy-sizes="(max-width: 914px) 100vw, 914px" title="Digital Marketing Agency For Startups" data-lazy-src="https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow.png" data-ll-status="loaded" sizes="(max-width: 914px) 100vw, 914px" srcset="https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow.png 914w, https://wodo.digital/wp-content/uploads/2022/03/WODO-Banner-Shadow-768x35.png 768w"></img> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="glow_circle_2" style={{ ...cursorPosition }}></div>
        </div>
        <div className="glow_circle_1" style={{ ...cursorPosition }}></div>
      </section>
    </>
  );
}

export default HeroSection;
